import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  FormControl,
  Spinner,
  Card,
  Table,
  Accordion,
  Tabs,
  Tab
} from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import ApiService from '../../helpers/apiService';
import IconButton from '../common/IconButton';
import './ProfilePage.css';

interface ProfileData {
  userId: string;
  name: string;
  birth_date: string;
  address: string;
  documents: string;
  check_status: string;
  status: string;
  is_manager: boolean;
  account_list: Account[];
}

interface Account {
  account: string;
  currency: string;
  sum: string;
}

interface Limit {
  id: number;
  code: string;
  object_type: number;
  action_type: number;
  limit_type: number;
  value: string;
  remaining: string | null;
  duration: number;
  started_at: string;
}

const ProfilePage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState<ProfileData | null>(null);
  const [limitsData, setLimitsData] = useState<Limit[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [activeTab, setActiveTab] = useState<string>('user');

  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get('userId');

  const fetchProfileData = async (userId: string) => {
    setLoading(true);
    setError(null);
    try {
      const data = await ApiService.get<{ data: ProfileData[] }>(
        '/user/v1/list',
        {
          params: { userId },
          headers: {
            requestId: 'ваш_requestId',
            Authorization: 'Bearer ваш_токен'
          }
        }
      );
      setProfileData(data.data[0]);
    } catch (err) {
      setError('Не удалось загрузить данные пользователя');
    } finally {
      setLoading(false);
    }
  };

  const fetchLimitsData = async (objectType: number) => {
    setLoading(true);
    const accountId = profileData?.account_list[0]?.account || '';
    const objects = [userId, objectType === 1 ? accountId : null]
      .filter(Boolean)
      .join(',');

    try {
      const response = await ApiService.get<{ data: Limit[] }>(
        '/support/v1/limits',
        {
          params: { objects },
          headers: {
            requestId: 'ваш_requestId',
            Authorization: 'Bearer ваш_токен'
          }
        }
      );
      setLimitsData(response.data);
    } catch (error) {
      console.error('Error fetching limits data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchProfileData(userId);
      fetchLimitsData(0); // Fetch user limits initially
    }
  }, [userId]);

  // Fetch limits data whenever activeTab changes
  useEffect(() => {
    if (activeTab === 'user') {
      fetchLimitsData(0);
    } else if (activeTab === 'account') {
      fetchLimitsData(1);
    } else if (activeTab === 'card') {
      fetchLimitsData(2);
    }
  }, [activeTab, profileData]); // Add profileData as dependency to ensure accountId is available

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setSearchParams({ userId: searchQuery });
  };

  const closeDisableModal = () => setShowDisableModal(false);

  const renderActionType = (actionType: number) => {
    switch (actionType) {
      case 0:
        return 'Общий';
      case 1:
        return 'Лимит на пополнение';
      case 2:
        return 'Лимит на вывод';
      case 3:
        return 'Лимит на платежи по карте';
      case 4:
        return 'Лимит на перевод';
      case 5:
        return 'Лимит на получение переводов';
      default:
        return 'Неизвестно';
    }
  };

  const renderLimitType = (limitType: number) => {
    switch (limitType) {
      case 0:
        return 'Максимальная сумма';
      case 1:
        return 'Максимальное количество';
      case 2:
        return 'Максимальная сумма для одной операции';
      case 3:
        return 'Минимальная сумма для одной операции';
      default:
        return 'Неизвестно';
    }
  };

  const renderDuration = (duration: number) => {
    switch (duration) {
      case 0:
        return 'День';
      case 86400:
        return 'Неделя';
      case 2592000:
        return 'Месяц';
      default:
        return 'Неизвестно';
    }
  };

  return (
    <div className="ProfilePage-page container">
      <div className="search-section">
        <form onSubmit={handleSubmit}>
          <FormControl
            placeholder="Поиск по ФИО, телефону, номеру счёта"
            value={searchQuery}
            onChange={handleSearch}
          />
          {/*@ts-ignore*/}
          <IconButton
            type="submit"
            variant="primary"
            disabled={loading}
            icon="search"
          >
            Поиск
            {loading && (
              <Spinner as="span" animation="border" size="sm" role="status" />
            )}
          </IconButton>
        </form>
      </div>

      {loading && <p>Загрузка...</p>}
      {error && <p>{error}</p>}

      {profileData && (
        <Card className="ProfilePage-card">
          <div className="profile-details">
            <div className="details-section">
              <p>ФИО: {profileData.name || 'N/A'}</p>
              <p>Дата Рождения: {profileData.birth_date || 'N/A'}</p>
              <p>Адрес: {profileData.address || 'N/A'}</p>
              <p>Документы: {profileData.documents || 'N/A'}</p>
              <p>Проверка: {profileData.check_status || 'N/A'}</p>
              <p>Статус: {profileData.status || 'N/A'}</p>
              <p>Менеджер: {profileData.is_manager ? 'Да' : 'Нет'}</p>
            </div>
            <div className="photo-section">
              <div className="photo-placeholder">Фото</div>
            </div>
          </div>

          <Accordion defaultActiveKey="0" className="mt-3">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Счета (Accounts)</Accordion.Header>
              <Accordion.Body>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Счёт</th>
                      <th>Валюта</th>
                      <th>Баланс</th>
                    </tr>
                  </thead>
                  <tbody>
                    {profileData.account_list?.map((account, index) => (
                      <tr key={index}>
                        <td>{account.account}</td>
                        <td>{account.currency}</td>
                        <td>{account.sum || 'N/A'}</td>
                      </tr>
                    )) || (
                      <>
                        <tr>
                          <td>Счёт_1</td>
                          <td>USD</td>
                          <td>1000,2</td>
                        </tr>
                        <tr>
                          <td>Счёт_2</td>
                          <td>ETH</td>
                          <td>0,0025</td>
                        </tr>
                        <tr>
                          <td>Счёт_3</td>
                          <td>USDT</td>
                          <td>10,25</td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>Ограничения (Restrictions)</Accordion.Header>
              <Accordion.Body>
                <Tabs
                  activeKey={activeTab}
                  onSelect={tab => tab && setActiveTab(tab)}
                  id="profile-tabs"
                  className="mb-3"
                >
                  <Tab eventKey="user" title="Пользователь">
                    <div className="table-container">
                      <Table
                        striped
                        bordered
                        hover
                        className="restrictions-table"
                      >
                        <thead>
                          <tr>
                            <th>Наименование</th>
                            <th>Мнемоника</th>
                            <th>Тип лимита</th>
                            <th>Тип ограничения</th>
                            <th>Лимит</th>
                            <th>Остаток лимита</th>
                            <th>Интервал</th>
                            <th>Время старта</th>
                          </tr>
                        </thead>
                        <tbody>
                          {limitsData.map(limit => (
                            <tr key={limit.id}>
                              <td>{limit.code}</td>
                              <td>{limit.code}</td>
                              <td>{renderActionType(limit.action_type)}</td>
                              <td>{renderLimitType(limit.limit_type)}</td>
                              <td>{limit.value}</td>
                              <td>{limit.remaining ?? 'N/A'}</td>
                              <td>{renderDuration(limit.duration)}</td>
                              <td>
                                {new Date(limit.started_at).toLocaleString()}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </Tab>

                  <Tab eventKey="account" title="Счет">
                    <div className="table-container">
                      <Table
                        striped
                        bordered
                        hover
                        className="restrictions-table"
                      >
                        <thead>
                          <tr>
                            <th>Наименование</th>
                            <th>Мнемоника</th>
                            <th>Тип лимита</th>
                            <th>Тип ограничения</th>
                            <th>Лимит</th>
                            <th>Остаток лимита</th>
                            <th>Интервал</th>
                            <th>Время старта</th>
                          </tr>
                        </thead>
                        <tbody>
                          {limitsData.map(limit => (
                            <tr key={limit.id}>
                              <td>{limit.code}</td>
                              <td>{limit.code}</td>
                              <td>{renderActionType(limit.action_type)}</td>
                              <td>{renderLimitType(limit.limit_type)}</td>
                              <td>{limit.value}</td>
                              <td>{limit.remaining ?? 'N/A'}</td>
                              <td>{renderDuration(limit.duration)}</td>
                              <td>
                                {new Date(limit.started_at).toLocaleString()}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </Tab>

                  <Tab eventKey="card" title="Карта">
                    <div className="table-container">
                      <Table
                        striped
                        bordered
                        hover
                        className="restrictions-table"
                      >
                        <thead>
                          <tr>
                            <th>Наименование</th>
                            <th>Мнемоника</th>
                            <th>Тип лимита</th>
                            <th>Тип ограничения</th>
                            <th>Лимит</th>
                            <th>Остаток лимита</th>
                            <th>Интервал</th>
                            <th>Время старта</th>
                          </tr>
                        </thead>
                        <tbody>
                          {limitsData.map(limit => (
                            <tr key={limit.id}>
                              <td>{limit.code}</td>
                              <td>{limit.code}</td>
                              <td>{renderActionType(limit.action_type)}</td>
                              <td>{renderLimitType(limit.limit_type)}</td>
                              <td>{limit.value}</td>
                              <td>{limit.remaining ?? 'N/A'}</td>
                              <td>{renderDuration(limit.duration)}</td>
                              <td>
                                {new Date(limit.started_at).toLocaleString()}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </Tab>
                </Tabs>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Card>
      )}

      <Modal show={showDisableModal} onHide={closeDisableModal}>
        <Modal.Header closeButton>
          <Modal.Title>Выберите аккаунты для отключения</Modal.Title>
        </Modal.Header>
        <Modal.Body>Список аккаунтов для отключения</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDisableModal}>
            Закрыть
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProfilePage;
