import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import ApiService from 'helpers/apiService'; // Import ApiService for API requests

interface Access {
  id: string;
  description: string;
}

const ProfilesList: React.FC = () => {
  const [accessList, setAccessList] = useState<Access[]>([]);
  const [rolesList, setRolesList] = useState<any[]>([]); // For roles (groups)
  const [showForm, setShowForm] = useState(false);
  const [currentRole, setCurrentRole] = useState<any | null>(null); // Track the current role being edited
  const [selectedAccessIds, setSelectedAccessIds] = useState<string[]>([]);
  const [tab, setTab] = useState('roles'); // To toggle between 'roles' and 'groups'

  const requestId = '4b9f0e28-0131-4a18-8c5d-e9eed02e0273'; // Generate or retrieve the requestId

  // Fetch list of access on component mount
  useEffect(() => {
    if (tab === 'roles') {
      ApiService.getListAccess(requestId)
        .then(response => setAccessList(response.data))
        .catch(error => console.error('Error fetching access list:', error));
    } else if (tab === 'groups') {
      ApiService.getListRoles(requestId)
        .then(response => setRolesList(response.data))
        .catch(error => console.error('Error fetching roles list:', error));
    }
  }, [tab, requestId]);

  // Add role (group)
  const handleAddRole = (data: any) => {
    ApiService.addRole(data, requestId)
      .then(() => {
        setShowForm(false);
        setCurrentRole(null); // Clear the current role
        ApiService.getListRoles(requestId).then(response =>
          setRolesList(response.data)
        ); // Refresh the roles list after adding
      })
      .catch(error => console.error('Error adding role:', error));
  };

  // Edit role (group)
  const handleEditRole = (data: any) => {
    ApiService.editRole(data, requestId)
      .then(() => {
        setShowForm(false);
        setCurrentRole(null); // Clear the current role
        ApiService.getListRoles(requestId).then(response =>
          setRolesList(response.data)
        ); // Refresh the roles list after editing
      })
      .catch(error => console.error('Error editing role:', error));
  };

  // Delete role (group)
  const handleDeleteRole = (idRole: string) => {
    ApiService.deleteRole(idRole, requestId)
      .then(() => {
        setRolesList(rolesList.filter(role => role.id !== idRole)); // Remove the deleted role from the list
      })
      .catch(error => console.error('Error deleting role:', error));
  };

  // Handle form submission for both add and edit
  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const data = {
      id: formData.get('idRole'),
      description: formData.get('description'),
      access: selectedAccessIds // Array of selected access IDs
    };
    if (currentRole) {
      handleEditRole(data); // If editing, submit as an edit
    } else {
      handleAddRole(data); // Otherwise, add a new role
    }
  };

  // Handle role edit button click
  const handleEditButtonClick = (role: any) => {
    setCurrentRole(role); // Set the current role to be edited
    setSelectedAccessIds(role.access); // Prepopulate the selected access for editing
    setShowForm(true); // Show the form
  };

  // Handle form cancellation
  const handleCancel = () => {
    setCurrentRole(null); // Clear the current role
    setSelectedAccessIds([]); // Clear selected access
    setShowForm(false); // Hide the form
  };

  // Handle checkbox toggle
  const handleAccessChange = (id: string) => {
    setSelectedAccessIds(prevSelected => {
      const safeSelected = prevSelected || []; // Ensure it's always an array
      return safeSelected.includes(id)
        ? safeSelected.filter(accessId => accessId !== id) // Remove if already selected
        : [...safeSelected, id]; // Add if not selected
    });
  };

  return (
    <div className="profiles-page" style={{ padding: '20px' }}>
      <h2>Справочники</h2>

      {/* Tab buttons */}
      <div className="mb-4">
        <Button
          variant={tab === 'roles' ? 'primary' : 'secondary'}
          onClick={() => setTab('roles')}
        >
          Доступы
        </Button>
        <Button
          variant={tab === 'groups' ? 'primary' : 'secondary'}
          onClick={() => setTab('groups')}
        >
          Роли
        </Button>
      </div>

      {/* Roles Tab */}
      {tab === 'roles' && (
        <div
          style={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px'
          }}
        >
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID Роли</th>
                <th>Описание</th>
              </tr>
            </thead>
            <tbody>
              {accessList.map((access, index) => (
                <tr key={index}>
                  <td>{access.id}</td>
                  <td>{access.description}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}

      {/* Groups Tab */}
      {tab === 'groups' && (
        <div
          style={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px'
          }}
        >
          <div className="d-flex justify-content-end mb-4">
            <Button variant="primary" onClick={() => setShowForm(true)}>
              {currentRole ? 'Редактировать группу' : 'Добавить группу'}
            </Button>
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID Группы</th>
                <th>Описание</th>
                <th>Доступы</th>
                <th>Действия</th>
              </tr>
            </thead>
            <tbody>
              {rolesList.map((role, index) => (
                <tr key={index}>
                  <td>{role.id}</td>
                  <td>{role.description}</td>
                  <td>{role?.access?.join(', ')}</td>
                  <td>
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      onClick={() => handleEditButtonClick(role)}
                    >
                      ✏️
                    </Button>{' '}
                    <Button
                      variant="outline-danger"
                      size="sm"
                      onClick={() => handleDeleteRole(role.id)}
                    >
                      🗑️
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}

      {showForm && (
        <div
          style={{
            backgroundColor: '#e7f3ff',
            padding: '20px',
            borderRadius: '8px',
            marginTop: '20px'
          }}
        >
          <Form onSubmit={handleFormSubmit}>
            <Row>
              <Col>
                <Form.Group controlId="idRole">
                  <Form.Label>ID Роли/Группы</Form.Label>
                  <Form.Control
                    type="text"
                    name="idRole"
                    defaultValue={currentRole ? currentRole.id : ''}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Group controlId="description">
                  <Form.Label>Описание</Form.Label>
                  <Form.Control
                    type="text"
                    name="description"
                    defaultValue={currentRole ? currentRole.description : ''}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Group controlId="access">
                  <Form.Label>Доступы</Form.Label>
                  {accessList.map(access => (
                    <Form.Check
                      key={access.id}
                      type="checkbox"
                      id={`access-${access.id}`}
                      label={access.description}
                      checked={selectedAccessIds?.includes(access.id)}
                      onChange={() => handleAccessChange(access.id)}
                    />
                  ))}
                </Form.Group>
              </Col>
            </Row>
            <div className="d-flex justify-content-end mt-3">
              <Button
                variant="secondary"
                onClick={handleCancel}
                className="me-2"
              >
                Отмена
              </Button>
              <Button variant="primary" type="submit">
                Сохранить
              </Button>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};

export default ProfilesList;
