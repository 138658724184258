export default [
  {
    version: '1.0.1',
    title: 'Главное окно',
    publish: '20 Мая, 2024',
    logs: {
      Новое: [
        'Создание проекта',
        'Выкладка в Git',
        'Подготовка главного окна, чистка',
        'Главное меню',
        'Страница ченджлога для фиксации разработок'
      ]
    }
  },
  {
    version: '1.0.2',
    title: 'Транзакции.Поиск операций',
    publish: '21 Мая, 2024',
    logs: {
      Новое: ['Page : <code> Transactions </code>', 'Prettify code']
    }
  },
  // {
  //   version: '3.0.0-alpha2',
  //   title: "Dickinson's kestrel",
  //   publish: '07 September, 2021',
  //   logs: {
  //     New: [
  //       'Page : <code> Analytics </code>',
  //       'Page : <code> Feed </code>',
  //       'Page : <code> Activity Log </code>',
  //       'Page : <code> Notifications </code>',
  //       'Page : <code> Followers </code>',
  //       'Page : <code> Profile </code>',
  //       'Page : <code> Settings </code>',
  //       'Page : <code> Associations </code>',
  //       'Page : <code> Advance tables </code>',
  //       'Page : <code> Lightbox </code>',
  //       'Page : <code> Placeholder </code>',
  //       'Plugin : react-table',
  //       'Plugin : react-image-lightbox',
  //       'Plugin : video.js'
  //     ],
  //     Update: ['Bootstrap to v5.1.0', 'React-Bootstrap to v2.0.0-beta.6'],
  //     Migration: {
  //       Add: [
  //         '<code>src/components/dashboards/analytics/**/*</code>',
  //         '<code>src/components/app/**/*</code>',
  //         '<code>src/components/pages/user/**/*</code>',
  //         '<code>src/components/pages/asscociations/*</code>',
  //         '<code>src/components/notification/*</code>',
  //         '<code>src/components/common/SubtleBadge.js</code>',
  //         '<code>src/components/common/FalconLink.js</code>',
  //         '<code>src/components/bootstrap-components/Placeholder.js</code>',
  //         '<code>src/components/bootstrap-components/Lightbox.js</code>',
  //         '<code>src/components/common/AdvanceTable.js</code>',
  //         '<code>src/components/bootstrap-components/AdvanceTable.js</code>',
  //         '<code>src/assets/json/world.json</code>',
  //         '<code>src/assets/scss/theme/plugins/_echarts.scss</code>',
  //         '<code>src/assets/scss/theme/plugins/_react-table.scss</code>',
  //         '<code>src/helpers/echart-utils.js</code>',
  //         '<code>src/reducers/feedReducer.js</code>'
  //       ],
  //       Update: [
  //         '<code> src/components/navbar/top/ProfileDropdown.js </code>',
  //         '<code> src/components/bootstrap-components/Badges.js </code>',
  //         '<code> src/components/bootstrap-components/ListGroup.js </code>',
  //         '<code> src/components/bootstrap-components/Tables.js </code>',
  //         '<code> src/components/dashboards/default/RunningProjects.js </code>',
  //         '<code> src/components/dashboards/default/WeeklySales.js </code>',
  //         '<code> src/components/navbar/top/SearchBox.js </code>',
  //         '<code> src/components/navbar/vertical/NavbarVerticalMenuItem.js</code>',
  //         '<code> src/components/pages/ComingSoon.js </code>',
  //         '<code> src/components/settings-panel/SettingsPanel.js </code>',
  //         '<code> src/components/bootstrap-components/FormValidation.js </code>',
  //         '<code> src/helpers/initFA.js </code>',
  //         '<code> src/assets/scss/_bootstrap.scss </code>',
  //         '<code> src/assets/scss/theme/_variables.scss </code>',
  //         '<code> src/assets/scss/theme/_avatar.scss </code>',
  //         '<code> src/assets/scss/theme/_functions.scss </code>',
  //         '<code> src/assets/scss/theme/_popover.scss </code>',
  //         '<code> src/assets/scss/theme/_popover.scss </code>',
  //         '<code> src/assets/scss/theme/_dashboard.scss </code>',
  //         '<code> src/assets/scss/theme/_plugins.scss </code>',
  //         '<code> src/assets/scss/theme/_table.scss </code>',
  //         '<code> src/assets/scss/theme/mixins/_utilities.scss </code>'
  //       ]
  //     },
  //     Fix: ['Authentication Modal issue']
  //   }
  // },
  {
    version: '1.0.0',
    title: 'Инициализация проекта',
    publish: '10 Май, 2024'
  }
];
