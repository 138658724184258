import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';

// Components
import { Spinner } from 'react-bootstrap';
import Select from 'react-select';
import IconButton from 'components/common/IconButton';

// Data
import currenciesData from 'data/currencies';

// Types
import { ICurrencyPair } from './types';
import { ICurrency } from 'components/transactions/types';

type PropsTypes = {
  pairs: ICurrencyPair[];
  getCurrencyPairs: () => void;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const PairsCreate = (props: PropsTypes) => {
  const { pairs, loading, setLoading, getCurrencyPairs } = props;

  const [currencies, setCurrencies] = useState<ICurrency[]>([]);
  const [currencyFromOptions, setCurrencyFromOptions] = useState<ICurrency[]>(
    []
  );
  const [currencyToOptions, setCurrencyToOptions] = useState<ICurrency[]>([]);

  const getCurrencies = async () => {
    const currencies = await currenciesData; //Request for currencies
    setCurrencies(currencies);
    setCurrencyFromOptions(currencies);
    setCurrencyToOptions(currencies);
  };

  const handlerSubmit = async () => {
    setLoading(true);
    // Request for create pair
    getCurrencyPairs(); // after success response
  };

  const handleCurrencyFromSelect = (selectedValue: ICurrency) => {
    const { value } = selectedValue;
    setFieldValue('currencyFrom', selectedValue);
    const disabledCurrency = pairs?.reduce(
      (acc, pair) => [
        ...acc,
        ...(pair?.currencyFrom === value ? [pair?.currencyTo] : [])
      ],
      [value]
    );
    setCurrencyToOptions(
      currencies?.filter(option => !disabledCurrency.includes(option?.value))
    );
  };

  const handleCurrencyToSelect = (selectedValue: ICurrency) => {
    const { value } = selectedValue;
    setFieldValue('currencyTo', selectedValue);
    const disabledCurrency = pairs?.reduce(
      (acc, pair) => [
        ...acc,
        ...(pair?.currencyTo === value ? [pair?.currencyFrom] : [])
      ],
      [value]
    );
    setCurrencyFromOptions(
      currencies?.filter(option => !disabledCurrency.includes(option?.value))
    );
  };

  const {
    handleSubmit,
    setFieldValue,
    values: { currencyFrom, currencyTo }
  } = useFormik({
    initialValues: {
      currencyFrom: '',
      currencyTo: ''
    },
    onSubmit: handlerSubmit
  });

  useEffect(() => {
    getCurrencies();
  }, []);

  return (
    <div className="w-100 mb-4">
      <div className="w-75 d-flex gap-2 search-box">
        <Select
          closeMenuOnSelect={false}
          options={currencyFromOptions as any}
          placeholder="Валюта"
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              maxHeight: state?.hasValue ? 'unset' : '36px'
            })
          }}
          className="w-100"
          classNamePrefix="react-select"
          value={currencyFrom}
          onChange={handleCurrencyFromSelect as any}
        />
        <Select
          closeMenuOnSelect={false}
          options={currencyToOptions as any}
          placeholder="Валюта"
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              maxHeight: state?.hasValue ? 'unset' : '36px'
            })
          }}
          className="w-100"
          classNamePrefix="react-select"
          value={currencyTo}
          onChange={handleCurrencyToSelect as any}
        />
        {/* @ts-ignore */}
        <IconButton
          icon="plus"
          iconAlign="left"
          className="d-flex align-items-center gap-2"
          variant="primary"
          id="button-addon2"
          disabled={loading || !currencyFrom || !currencyTo}
          onClick={handleSubmit as any}
        >
          Создать
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : null}
        </IconButton>
      </div>
    </div>
  );
};

export default PairsCreate;
