import React, { useState } from 'react';
import {
  Table,
  Tooltip,
  OverlayTrigger,
  Form,
  Modal,
  Button
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ApiService from '../../../helpers/apiService';

interface User {
  userId: string;
  name: string;
  kind: string;
  start_date: string;
  update_date?: string;
  account_list: {
    account: string;
    currency: string;
    kind: string;
    type: string;
  }[];
  kyc_data?: {
    doc_id: string;
    doc_type: string;
    number: string;
    series: string;
    issue_data: string;
    exp_date: string;
    resident: string;
  }[];
  aml_data?: {
    doc_id: string;
    status: string;
    comment: string;
  }[];
  is_manager: boolean;
  is_enabled: boolean;
  data_company?: string[];
  status: string;
  scoring?: {
    manual_block: boolean;
  };
}

interface UsersTableProps {
  users: User[];
  fetchUsers: () => void;
}

const UsersTable: React.FC<UsersTableProps> = ({ users, fetchUsers }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [blockReason, setBlockReason] = useState('');
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSwitchToggle = (user: User) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const handleBlockUnblockUser = async () => {
    if (!selectedUser) return;

    setLoading(true);
    const apiMethod = selectedUser.is_enabled
      ? ApiService.blockUser.bind(ApiService)
      : ApiService.unblockUser.bind(ApiService);

    try {
      await apiMethod(selectedUser.userId, `${blockReason}. ${comment}`);
      setShowModal(false);
      setBlockReason('');
      setComment('');
      fetchUsers();
    } catch (error) {
      console.error('Ошибка при блокировке/разблокировке:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>User ID</th>
            <th>Name</th>
            <th>Start Date</th>
            <th>Accounts</th>
            <th>KYC</th>
            <th>Manager</th>
            <th>Enabled</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.userId}>
              {/* User ID */}
              <td>
                <Link to={`/checks/profile?userId=${user.userId}`}>
                  {user.userId}
                </Link>
              </td>

              {/* Name */}
              <td>{user.name.trim() ? user.name : 'No Name'}</td>

              {/* Start Date */}
              <td>
                {user.start_date !== '0001-01-01T00:00:00Z'
                  ? user.start_date
                  : 'N/A'}
              </td>

              {/* Account List */}
              <td>
                {user.account_list && user.account_list.length > 0 ? (
                  <Link to={`/accounts?userId=${user.userId}`}>
                    View Accounts
                  </Link>
                ) : (
                  'No accounts'
                )}
              </td>

              {/* KYC Data */}
              <td>
                {user.kyc_data && user.kyc_data.length > 0 ? (
                  <Link to={`/kyc/${user.userId}`}>View KYC</Link>
                ) : (
                  'No KYC'
                )}
              </td>

              {/* Is Manager */}
              <td>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      {user.data_company?.length
                        ? user.data_company.join(', ')
                        : 'No companies'}
                    </Tooltip>
                  }
                >
                  <Form.Check
                    type="switch"
                    checked={user.is_manager}
                    readOnly
                    aria-label="Manager"
                  />
                </OverlayTrigger>
              </td>

              {/* Is Enabled */}
              <td>
                <Form.Check
                  type="switch"
                  checked={user.is_enabled}
                  onChange={() => handleSwitchToggle(user)}
                  aria-label="Block/Unblock User"
                />
              </td>

              {/* Status */}
              <td>{user.status || 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for Blocking/Unblocking */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedUser?.is_enabled ? 'Блокировка' : 'Разблокировка'}{' '}
            пользователя
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="blockReasonSelect">
            <Form.Label>
              Причина{' '}
              {selectedUser?.is_enabled ? 'блокировки' : 'разблокировки'}
            </Form.Label>
            <Form.Control
              as="select"
              value={blockReason}
              onChange={e => setBlockReason(e.target.value)}
            >
              <option value="">Выберите причину</option>
              <option value="reason1">Причина 1</option>
              <option value="reason2">Причина 2</option>
              {/* добавьте другие причины */}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="descriptionInput">
            <Form.Label>Описание (комментарий)</Form.Label>
            <Form.Control
              as="textarea"
              value={comment}
              onChange={e => setComment(e.target.value)}
              placeholder="Введите описание причины"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Отмена
          </Button>
          <Button
            variant="primary"
            onClick={handleBlockUnblockUser}
            disabled={loading}
          >
            {loading ? 'Обработка...' : 'Подтвердить'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UsersTable;
