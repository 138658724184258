import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import ApiService from 'helpers/apiService';
import { v4 as uuidv4 } from 'uuid';

const ProfilesList: React.FC = () => {
  const [operators, setOperators] = useState<any[]>([]);
  const [selectedOperator, setSelectedOperator] = useState<any | null>(null);
  const [showForm, setShowForm] = useState(false);
  const [rolesList, setRolesList] = useState<any[]>([]);
  const [accessList, setAccessList] = useState<any[]>([]);
  const [selectedAccessIds, setSelectedAccessIds] = useState<string[]>([]);
  const [selectedRoleId, setSelectedRoleId] = useState<string | null>(null);

  const requestId = uuidv4();

  // Получение списка операторов
  const fetchOperators = async () => {
    try {
      const response = await ApiService.getOperators<any>(requestId);
      setOperators(response.data);
    } catch (error) {
      console.error('Failed to fetch operators:', error);
    }
  };

  // Получение списка ролей и прав доступа
  const fetchRolesAndAccess = async () => {
    try {
      const rolesResponse = await ApiService.getListRoles(requestId);
      const accessResponse = await ApiService.getListAccess(requestId);
      setRolesList(rolesResponse.data);
      setAccessList(accessResponse.data);
    } catch (error) {
      console.error('Failed to fetch roles or access:', error);
    }
  };

  useEffect(() => {
    fetchOperators();
    fetchRolesAndAccess();
  }, []);

  useEffect(() => {
    if (selectedOperator) {
      setSelectedAccessIds(selectedOperator.roles || []);
      setSelectedRoleId(selectedOperator.roleId || null);
    }
  }, [selectedOperator]);

  // Обработка изменения группы и обновление прав доступа на основе выбранной группы
  const handleRoleChange = (roleId: string) => {
    setSelectedRoleId(roleId);
    const selectedRole = rolesList.find(role => role.id === roleId);
    setSelectedAccessIds(selectedRole ? selectedRole.access : []);
  };

  // Обработка изменений в чекбоксах доступа
  const handleAccessChange = (id: string) => {
    const newSelectedAccessIds = selectedAccessIds.includes(id)
      ? selectedAccessIds.filter(accessId => accessId !== id)
      : [...selectedAccessIds, id];
    setSelectedAccessIds(newSelectedAccessIds);
    setSelectedRoleId(null); // Сброс выбранной группы до "Выбрать"
  };

  // Обработка отправки формы для добавления/редактирования операторов
  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;

    const nameInput = form.elements.namedItem('name') as HTMLInputElement;
    const phoneInput = form.elements.namedItem('phone') as HTMLInputElement;
    const emailInput = form.elements.namedItem('email') as HTMLInputElement;

    const name = nameInput.value.trim();
    const phone = phoneInput.value.trim();
    const email = emailInput.value.trim();

    if (!name) {
      console.error('Name is required');
      return;
    }

    const profileType = form.elements.namedItem(
      'profileType'
    ) as HTMLSelectElement;
    const group = form.elements.namedItem('group') as HTMLSelectElement;
    const enabled = selectedOperator?.status === 'active';

    const newOperator = {
      id: selectedOperator ? selectedOperator.id : uuidv4(),
      name,
      phone: phone || '+71234567890',
      email: email || '',
      profileType: profileType.value,
      group: group.value,
      enabled: enabled,
      roles: selectedAccessIds,
      roleId: selectedRoleId,
      status: enabled ? 'active' : 'blocked'
    };

    try {
      if (selectedOperator) {
        await ApiService.editOperator(newOperator, requestId);
        setOperators(
          operators.map(op =>
            op.id === selectedOperator.id ? newOperator : op
          )
        );
      } else {
        await ApiService.addOperator(newOperator, requestId);
        setOperators([...operators, newOperator]);
      }

      setShowForm(false);
    } catch (error) {
      console.error('Failed to save operator:', error);
    }
  };

  return (
    <div className="profiles-page" style={{ padding: '20px' }}>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Операторы</h2>
        {!showForm && (
          <Button variant="primary" onClick={() => setShowForm(true)}>
            Добавить оператора
          </Button>
        )}
      </div>

      {!showForm && (
        <div
          style={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px'
          }}
        >
          {operators.length > 0 ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Оператор</th>
                  <th>Тип профиля</th>
                  <th>Включение</th>
                  <th>Группа</th>
                  <th>Роли</th>
                  <th>Управление</th>
                </tr>
              </thead>
              <tbody>
                {operators.map((operator, index) => (
                  <tr key={index}>
                    <td>{operator.name}</td>
                    <td>
                      {operator.type === '1' ? 'Оператор' : 'Фин. контролер'}
                    </td>
                    <td>{operator.status === 'active' ? '✔️' : '❌'}</td>
                    <td>{operator.group}</td>
                    <td>{operator.roles.join(', ')}</td>
                    <td>
                      <Button
                        variant="outline-secondary"
                        size="sm"
                        onClick={() => {
                          setSelectedOperator(operator);
                          setShowForm(true);
                        }}
                        style={{ marginRight: '5px' }}
                      >
                        ✏️
                      </Button>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={async () => {
                          try {
                            await ApiService.deleteOperator(
                              operator.id,
                              requestId
                            );
                            setOperators(
                              operators.filter(op => op.id !== operator.id)
                            );
                          } catch (error) {
                            console.error('Failed to delete operator:', error);
                          }
                        }}
                      >
                        🗑️
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>Нет доступных операторов</p>
          )}
        </div>
      )}

      {showForm && (
        <div
          style={{
            backgroundColor: '#e7f3ff',
            padding: '20px',
            borderRadius: '8px',
            marginTop: '20px'
          }}
        >
          <Form onSubmit={handleFormSubmit}>
            <Row>
              <Col>
                <Form.Group controlId="operatorName">
                  <Form.Label>ФИО</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    defaultValue={selectedOperator ? selectedOperator.name : ''}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Group controlId="operatorPhone">
                  <Form.Label>Телефон</Form.Label>
                  <Form.Control
                    type="tel"
                    name="phone"
                    defaultValue={
                      selectedOperator ? selectedOperator.phone : ''
                    }
                    placeholder="+71234567890"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Group controlId="operatorEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    defaultValue={
                      selectedOperator ? selectedOperator.email : ''
                    }
                    placeholder="example@domain.com"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Group controlId="profileType">
                  <Form.Label>Тип профиля</Form.Label>
                  <Form.Control
                    as="select"
                    name="profileType"
                    defaultValue={
                      selectedOperator ? selectedOperator.profileType : ''
                    }
                  >
                    <option value="1">Оператор</option>
                    <option value="0">Фин. контролёр</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Group controlId="group">
                  <Form.Label>Группа</Form.Label>
                  <Form.Control
                    as="select"
                    name="group"
                    value={selectedRoleId || ''}
                    onChange={e => handleRoleChange(e.target.value)}
                  >
                    <option value="">Выбрать</option> {/* Опция по умолчанию */}
                    {rolesList.map(role => (
                      <option key={role.id} value={role.id}>
                        {role.description}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Group controlId="roles">
                  <Form.Label>Роли</Form.Label>
                  {accessList.map(access => (
                    <Form.Check
                      key={access.id}
                      type="checkbox"
                      id={`access-${access.id}`}
                      label={access.description}
                      checked={selectedAccessIds.includes(access.id)}
                      onChange={() => handleAccessChange(access.id)}
                    />
                  ))}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Group controlId="enabled">
                  <Form.Check
                    type="switch"
                    name="enabled"
                    label="Активен"
                    checked={selectedOperator?.status === 'active'}
                    onChange={() => {
                      // @ts-ignore
                      setSelectedOperator(prevOperator => ({
                        ...prevOperator,
                        status:
                          prevOperator?.status === 'active'
                            ? 'blocked'
                            : 'active'
                      }));
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="d-flex justify-content-end mt-3">
              <Button
                variant="secondary"
                onClick={() => setShowForm(false)}
                className="me-2"
              >
                Назад
              </Button>
              <Button variant="primary" type="submit">
                Сохранить
              </Button>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};

export default ProfilesList;
