import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import paths from 'routes/paths';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const renderTooltip = props => (
  <Tooltip style={{ position: 'fixed' }} id="button-tooltip" {...props}>
    Поддержка
  </Tooltip>
);

const SupportButton = () => {
  return (
    <OverlayTrigger placement="bottom" overlay={renderTooltip}>
      <Nav.Item as="li" className="m-0">
        <Nav.Link as={Link} to={paths.support} className="px-0">
          <FontAwesomeIcon
            icon="comments"
            transform="shrink-7"
            className="fs-5"
          />
        </Nav.Link>
      </Nav.Item>
    </OverlayTrigger>
  );
};

export default SupportButton;
