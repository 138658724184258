import React, { useState } from 'react';
import { Button, Card, Table, Accordion, Form } from 'react-bootstrap';

const ProfilePage = () => {
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [formData, setFormData] = useState({
    ФИО: '',
    ДР: '',
    Адрес: '',
    Документы: ''
  });

  const users = ['ФИО_1', 'ФИО_2', 'ФИО_3', 'ФИО_4', 'ФИО_5'];

  const handleUserClick = (user: string) => {
    setSelectedUser(user);
    setFormData({
      ФИО: user,
      ДР: '', // Set initial values for other fields if needed
      Адрес: '',
      Документы: ''
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div className="ProfilePage-page d-flex">
      {/* Main Content */}
      <div className="flex-grow-1 p-4">
        <Accordion alwaysOpen>
          {users.map((user, index) => (
            <Accordion.Item eventKey={String(index)} key={index}>
              <Accordion.Header onClick={() => handleUserClick(user)}>
                {user}
              </Accordion.Header>
              <Accordion.Body>
                {selectedUser === user && (
                  <Card className="ProfilePage-card mb-3 p-3 rounded-4">
                    <div className="d-flex">
                      <div
                        className="flex-grow-1"
                        style={{ marginRight: '20px' }}
                      >
                        <Form>
                          <Form.Group controlId="formName" className="mb-3">
                            <Form.Label>ФИО</Form.Label>
                            <Form.Control
                              type="text"
                              name="ФИО"
                              value={formData.ФИО}
                              onChange={handleInputChange}
                              style={{ width: '80%' }}
                            />
                          </Form.Group>
                          <Form.Group controlId="formDOB" className="mb-3">
                            <Form.Label>ДР</Form.Label>
                            <Form.Control
                              type="text"
                              name="ДР"
                              value={formData.ДР}
                              onChange={handleInputChange}
                              style={{ width: '80%' }}
                            />
                          </Form.Group>
                          <Form.Group controlId="formAddress" className="mb-3">
                            <Form.Label>Адрес</Form.Label>
                            <Form.Control
                              type="text"
                              name="Адрес"
                              value={formData.Адрес}
                              onChange={handleInputChange}
                              style={{ width: '80%' }}
                            />
                          </Form.Group>
                          <Form.Group
                            controlId="formDocuments"
                            className="mb-3"
                          >
                            <Form.Label>Документы</Form.Label>
                            <Form.Control
                              type="text"
                              name="Документы"
                              value={formData.Документы}
                              onChange={handleInputChange}
                              style={{ width: '80%' }}
                            />
                          </Form.Group>
                        </Form>
                      </div>
                      <div className="photo-section">
                        <div
                          className="photo-placeholder bg-light border d-flex justify-content-center align-items-center"
                          style={{ height: '150px', width: '150px' }}
                        >
                          Фото
                        </div>
                      </div>
                    </div>

                    {/* SumSub Verification Section */}
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Наименование документа</th>
                          <th>Статус</th>
                          <th>Баллы</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Документ 1</td>
                          <td>Принят</td>
                          <td>85</td>
                        </tr>
                        {/* Add more rows as needed */}
                      </tbody>
                    </Table>

                    <div className="d-flex justify-content-end mt-3">
                      <Button variant="danger" className="me-2">
                        Отклонить
                      </Button>
                      <Button variant="success">Подтвердить</Button>
                    </div>
                  </Card>
                )}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default ProfilePage;
