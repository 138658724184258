const getQueryParams = (searchParams: URLSearchParams) => {
  return Array.from(searchParams.entries())?.reduce(
    (acc, [key, value]: [key: string, value: string]) => {
      if (key in acc) {
        if (Array.isArray(acc[key])) {
          acc[key].push(value);
        } else {
          acc[key] = [acc[key], value];
        }
      } else {
        acc[key] = value;
      }
      return acc;
    },
    {} as { [key: string]: any }
  );
};

export default getQueryParams;
