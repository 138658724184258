const profileTypes: { [key: string]: string } = {
  0: 'Юридическое лицо',
  1: 'Физическое лицо'
};

const profileKinds: { [key: string]: string } = {
  blocked: 'Заблокирован',
  success: 'Активный',
  'no-verified': 'Не верифицирован'
};

const actionKinds: { [key: string]: string } = {
  success: 'Заблокировать',
  blocked: 'Активировать',
  show: 'Информация',
  'no-verified': 'Активировать'
};

const SUMSUB_REVIEW_STATUSES: { [key: string]: string } = {
  completed: 'Завершено',
  pending: 'В ожидании',
  queued: 'В очереди',
  prechecked: 'Предварительно проверено',
  onHold: 'Приостановлено',
  none: 'Нет',
  'rejected-RETRY': 'Отклонено-Повторить',
  'rejected-FINAL': 'Отклонено-Окончательно',
  init: 'Инициация',
  actionsRequired: 'Требуются действия'
};

export { profileTypes, SUMSUB_REVIEW_STATUSES, profileKinds, actionKinds };
