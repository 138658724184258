import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import paths from 'routes/paths';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const renderTooltip = props => (
  <Tooltip style={{ position: 'fixed' }} id="button-tooltip" {...props}>
    Почта
  </Tooltip>
);

const EmailButton = () => {
  return (
    <OverlayTrigger placement="bottom" overlay={renderTooltip}>
      <Nav.Item as="li" className="">
        <Nav.Link as={Link} to={paths.email} className="px-0">
          <FontAwesomeIcon
            icon="envelope-open"
            transform="shrink-7"
            className="fs-5"
          />
        </Nav.Link>
      </Nav.Item>
    </OverlayTrigger>
  );
};

export default EmailButton;
