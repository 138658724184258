import React, { useState } from 'react';
import { Accordion, FormControl, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import IconButton from 'components/common/IconButton';
import { profileKinds, profileTypes } from './constants';
import { OptionType } from 'components/common/types';

type UsersFiltersPropsTypes = {
  values: {
    search: string;
    type?: OptionType;
    kind?: OptionType;
  };
  handleSubmit: () => void;
  setFieldValue: (field: string, value: any) => void;
  loading: boolean;
};

const UsersFilters = (props: UsersFiltersPropsTypes) => {
  const {
    values: { type, kind, search } = {},
    handleSubmit,
    setFieldValue,
    loading
  } = props;
  const [typeOptions] = useState(
    Object.entries(profileTypes)?.map(([value, key]) => ({
      value: value,
      label: key
    }))
  );
  const [kindOptions] = useState(
    Object.entries(profileKinds)?.map(([value, key]) => ({
      value: value,
      label: key
    }))
  );

  return (
    <div className="w-100">
      <h3 className="mb-3">Пользователи</h3>
      <div className="w-50 d-flex gap-3 mb-3">
        <div className="search-box">
          <FormControl
            name="account"
            type="search"
            value={search}
            onChange={e => setFieldValue('search', e?.target?.value)}
            placeholder="Введите номер телефона или имя пользователя"
            aria-label="Введите номер телефона или имя пользователя"
            disabled={loading}
            className=""
          />
        </div>
        {/* @ts-ignore */}
        <IconButton
          className="d-flex align-items-center gap-2"
          variant="primary"
          id="button-addon2"
          disabled={loading}
          icon="search"
          iconAlign="left"
          onClick={handleSubmit}
        >
          Поиск
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : null}
        </IconButton>
      </div>
      <Accordion className="mb-3">
        <div className="d-flex align-items-start justify-content-between">
          <div className="w-75 d-flex gap-3">
            <Select
              closeMenuOnSelect={false}
              options={typeOptions as any}
              placeholder="Тип пользователя"
              isDisabled={loading}
              className="w-50"
              classNamePrefix="react-select"
              value={type}
              onChange={value => setFieldValue('type', value)}
            />
            <Select
              closeMenuOnSelect={false}
              options={kindOptions as any}
              placeholder="Статус"
              isDisabled={loading}
              className="w-50"
              classNamePrefix="react-select"
              value={kind}
              onChange={value => setFieldValue('kind', value)}
            />
          </div>
        </div>
      </Accordion>
    </div>
  );
};

export default UsersFilters;
