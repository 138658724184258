import bitcoin from './bitcoin.svg';
import bnb from './bnb.svg';
import busd from './busd.svg';
import decred from './decred.svg';
import digibyte from './digibyte.svg';
import ethereum from './ethereum.svg';
import eur from './eur.svg';
import litecoin from './litecoin.svg';
import matic from './matic.svg';
import polkadot from './polkadot.svg';
import polygon from './polygon.svg';
import potcoin from './potcoin.svg';
import solana from './solana.svg';
import stellar from './stellar.svg';
import tether from './tether.svg';
import trc20 from './trc20.svg';
import tron from './tron.svg';
import usdc from './usdc.svg';

const currencies = {
  bitcoin,
  bnb,
  busd,
  decred,
  digibyte,
  ethereum,
  eur,
  litecoin,
  matic,
  polkadot,
  polygon,
  potcoin,
  solana,
  stellar,
  tether,
  trc20,
  tron,
  usdc
};

export default currencies;
