/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  setState
} from '@tanstack/react-table';
import IndeterminateCheckbox from 'components/common/advance-table/IndeterminateCheckbox';

const selectionColumn = (selectionColumnWidth, selectionHeaderClassname) => {
  return {
    id: 'selection',
    accessorKey: '',
    header: ({ table }) => (
      <IndeterminateCheckbox
        className="form-check mb-0"
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler()
        }}
      />
    ),
    cell: ({ row }) => (
      <IndeterminateCheckbox
        className="form-check mb-0"
        {...{
          checked: row.getIsSelected(),
          disabled: !row.getCanSelect(),
          indeterminate: row.getIsSomeSelected(),
          onChange: row.getToggleSelectedHandler()
        }}
      />
    ),
    meta: {
      headerProps: {
        className: selectionHeaderClassname,
        style: {
          width: selectionColumnWidth
        }
      },
      cellProps: {
        style: {
          width: selectionColumnWidth
        }
      }
    }
  };
};

const useAdvanceTable = ({
  columns,
  data,
  sortable,
  selection,
  selectionColumnWidth,
  selectionHeaderClassname,
  pagination,
  initialState,
  perPage = 10,
  page = 1,
  setPage,
  rowCount,
  previousPage,
  nextPage
}) => {
  const [state, setState] = useState({
    pagination: {
      pageSize: pagination ? perPage : data.length,
      pageIndex: page - 1,
      setPage,
      previousPage,
      nextPage
    },
    ...initialState
  });

  useEffect(() => {
    setState(prev => ({
      ...prev,
      pagination: {
        ...prev?.pagination,
        pageIndex: page - 1
      }
    }));
  }, [page]);

  const table = useReactTable({
    data,
    columns: selection
      ? [
          selectionColumn(selectionColumnWidth, selectionHeaderClassname),
          ...columns
        ]
      : columns,
    rowCount,
    enableSorting: sortable,
    manualPagination: true,
    onPaginationChange: setState,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state,
    initialState: state
  });

  return table;
};

export default useAdvanceTable;
