const CURRENCIES: { [key: string]: string } = {
  BTC: 'bitcoin',
  BNB: 'bnb',
  BUSD: 'busd',
  DCR: 'decred',
  DGB: 'digibyte',
  ETH: 'ethereum',
  EUR: 'eur',
  LTC: 'litecoin',
  MATIC: 'matic',
  DOT: 'polkadot',
  POT: 'potcoin',
  SOL: 'solana',
  XLM: 'stellar',
  USDT: 'tether',
  TRC20: 'trc20',
  TRX: 'tron',
  USDC: 'usdc'
};

export { CURRENCIES };
