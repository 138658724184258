import React from 'react';
import PageHeader from 'components/common/PageHeader';
import { Card } from 'react-bootstrap';

const Invoices = () => {
  return (
    <>
      <PageHeader
        title="Счета"
        description="Тут будут счета"
        className="mb-3"
      />
      <Card>
        <Card.Body>
          <p>Содержимое страницы</p>
        </Card.Body>
      </Card>
    </>
  );
};

export default Invoices;
