import React, { useEffect, useState } from 'react';

// Components
import {
  Accordion,
  FormControl,
  Spinner,
  useAccordionButton
} from 'react-bootstrap';
import Select from 'react-select';
import ReactDatePicker from 'react-datepicker';
import IconButton from 'components/common/IconButton';

// Data
import currenciesData from 'data/currencies';

// Types
import { OptionType } from 'components/common/types';

type AccountsFiltersPropsTypes = {
  values: {
    from: string;
    to: string;
    search: string;
    currencies: string;
  };
  handleSubmit: () => void;
  setFieldValue: (field: string, value: any) => void;
  loading: boolean;
};

type AccountsFilterTogglePropsTypes = {
  children: React.ReactNode;
  eventKey: string;
};
const AccountsFilterToggle = (props: AccountsFilterTogglePropsTypes) => {
  const { eventKey, children } = props;

  const decoratedOnClick = useAccordionButton(eventKey);
  return (
    //@ts-ignore
    <IconButton
      variant="primary"
      icon="filter"
      iconAlign="left"
      onClick={decoratedOnClick}
    >
      {children}
    </IconButton>
  );
};

const AccountsFilters = (props: AccountsFiltersPropsTypes) => {
  const {
    values: {
      from = '',
      to = '',
      search = '',
      currencies: currenciesValue = ''
    } = {},
    handleSubmit,
    setFieldValue,
    loading
  } = props;
  const [currencies, setCurrencies] = useState<OptionType[]>([]);

  const getCurrencies = async () => {
    const response = await currenciesData;
    if (response?.length) setCurrencies(response);
  };

  useEffect(() => {
    getCurrencies();
  }, []);

  return (
    <div className="w-100">
      <h3 className="mb-3">Счета</h3>
      <div className="w-50 d-flex gap-3 mb-3">
        <div className="search-box">
          <FormControl
            name="search"
            type="search"
            value={search}
            onChange={e => setFieldValue('search', e?.target?.value)}
            placeholder="Введите номера счёта/телефона/фамилию"
            aria-label="Введите номера счёта/телефона/фамилию"
            disabled={loading}
            className=""
          />
        </div>
        {/* @ts-ignore */}
        <IconButton
          className="d-flex align-items-center gap-2"
          variant="primary"
          id="button-addon2"
          disabled={loading}
          icon="search"
          iconAlign="left"
          onClick={handleSubmit}
        >
          Поиск
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : null}
        </IconButton>
      </div>
      <Accordion className="mb-3">
        <div className="d-flex align-items-start justify-content-between">
          <div className="w-75 d-flex gap-3">
            <Select
              closeMenuOnSelect={false}
              options={currencies as any}
              placeholder="Валюта"
              isMulti
              isDisabled={loading}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  maxHeight: state?.hasValue ? 'unset' : '36px'
                })
              }}
              className="w-25"
              classNamePrefix="react-select"
              value={currenciesValue}
              onChange={value => setFieldValue('currencies', value)}
            />
            <div className="d-flex gap-2 w-50">
              <ReactDatePicker
                popperPlacement="bottom-start"
                selected={from as any}
                onChange={date => setFieldValue('from', date)}
                formatWeekDay={day => day.slice(0, 3)}
                className="form-control"
                disabled={loading}
                placeholderText="Дата с"
              />
              <ReactDatePicker
                popperPlacement="bottom-start"
                selected={to as any}
                onChange={date => setFieldValue('to', date)}
                formatWeekDay={day => day.slice(0, 3)}
                className="form-control"
                disabled={loading}
                placeholderText="Дата по"
              />
            </div>
          </div>
          <AccountsFilterToggle eventKey="0">Фильтры</AccountsFilterToggle>
        </div>
        <Accordion.Collapse eventKey="0">
          <div className="py-4">Фильтры</div>
        </Accordion.Collapse>
      </Accordion>
    </div>
  );
};

export default AccountsFilters;
