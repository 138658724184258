import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Flex from '../Flex';
import { useAdvanceTableContext } from 'providers/AdvanceTableProvider';

export const AdvanceTablePagination = () => {
  const {
    getCanNextPage,
    getCanPreviousPage,
    getState,
    getPageCount,
    setState
  } = useAdvanceTableContext();

  const {
    pagination: { pageIndex, setPage: setPageIndex, previousPage, nextPage }
  } = getState();

  const handlePreviousPage = () => {
    previousPage(pageIndex - 1);
    setState(prev => ({
      ...prev,
      pagination: {
        ...prev?.pagination,
        pageIndex: prev?.pagination?.pageIndex - 1
      }
    }));
  };

  const handleNextPage = () => {
    nextPage(pageIndex);
    setState(prev => ({
      ...prev,
      pagination: { ...prev?.pagination, pageIndex: pageIndex + 1 }
    }));
  };

  const handleSetPage = page => {
    setPageIndex(page);
    setState(prev => ({
      ...prev,
      pagination: { ...prev?.pagination, pageIndex: page }
    }));
  };

  return (
    <Flex alignItems="center" justifyContent="center">
      <Button
        size="sm"
        variant="falcon-default"
        onClick={handlePreviousPage}
        className={classNames({ disabled: !getCanPreviousPage() })}
      >
        <FontAwesomeIcon icon="chevron-left" />
      </Button>
      <ul className="pagination mb-0 mx-2">
        {Array.from(Array(getPageCount()).keys()).map((page, index) => (
          <li key={page} className={classNames({ active: pageIndex === page })}>
            <Button
              size="sm"
              variant="falcon-default"
              className={classNames('page', {
                'me-2': index + 1 !== getPageCount()
              })}
              onClick={() => handleSetPage(page)}
            >
              {page + 1}
            </Button>
          </li>
        ))}
      </ul>
      <Button
        size="sm"
        variant="falcon-default"
        onClick={handleNextPage}
        className={classNames({ disabled: !getCanNextPage() })}
      >
        <FontAwesomeIcon icon="chevron-right" />
      </Button>
    </Flex>
  );
};

export default AdvanceTablePagination;
