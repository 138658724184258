import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';

// Components
import PairsCreate from './PairsCreate';
import PairsTable from './PairsTable';

// Data
import currencyPairsData from 'data/pairs';

// Types
import { ICurrencyPair } from './types';

const Pairs = () => {
  const [pairs, setPairs] = useState<ICurrencyPair[]>([]);
  const [loading, setLoading] = useState(false);

  const getCurrencyPairs = async () => {
    const currencyPairs = await currencyPairsData; //Запрос на торговые пары
    if (currencyPairs?.length) {
      setPairs(currencyPairs);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getCurrencyPairs();
  }, []);

  return (
    <>
      <Card>
        <Card.Body>
          <PairsCreate
            pairs={pairs}
            getCurrencyPairs={getCurrencyPairs}
            loading={loading}
            setLoading={setLoading}
          />
          <PairsTable
            pairs={pairs}
            getCurrencyPairs={getCurrencyPairs}
            setLoading={setLoading}
            loading={loading}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default Pairs;
