import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import editing from 'assets/img/icons/spot-illustrations/5.png';

const Home = () => {
  return (
    <Card>
      <Card.Body className="overflow-hidden p-lg-6">
        <Row className="align-items-center justify-content-between">
          <Col lg={6}>
            <img src={editing} className="img-fluid" alt="" />
          </Col>
          <Col lg={6} className="ps-lg-4 my-5 text-center text-lg-start">
            <h3 className="text-primary">Привет!</h3>
            <p className="lead">
              Это стартовая страница новой административной панели для TeslaPay.
            </p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Home;
