import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useSearchParams, useParams } from 'react-router-dom';

// Компоненты
import { Card } from 'react-bootstrap';
import TransactionsTable from './TransactionsTable';
import TransactionsFilters from './TransactionsFilters';

// Хелперы
import getQueryParams from 'helpers/getQueryParams.hl';

import ApiService from '../../helpers/apiService';

// Типы
import { ITransaction, ITransactionResponse } from './types';

type FiltersTypes = {
  limit: number;
  page: number;
  from?: string;
  to?: string;
  trxId?: string;
  account?: string;
  currencies?: string[];
};

const Transactions = () => {
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState<{
    countAll: number;
    data: ITransaction[];
  }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { transactionId } = useParams(); // Получаем параметр transactionId из URL

  const params = getQueryParams(searchParams);

  const handlerSubmit = async () => {
    setLoading(true);
    const preparedData = {
      ...values,
      ...(transactionId ? { trxId: transactionId } : {}),
      ...(values?.currencies?.length
        ? {
            currencies: values?.currencies?.map(
              (currency: { value: string; label: string }) => currency?.value
            )
          }
        : {})
    };
    setSearchParams(preparedData as any);
    const data = await ApiService.fetchTransactions<ITransactionResponse>(
      preparedData
    );
    setTransactions(data);
    setLoading(false);
  };

  const handlePageChange = async (page: number) => {
    if (!page) return;
    setLoading(true);
    const preparedData = {
      ...(params as FiltersTypes),
      page,
      ...(params?.trxId ? { trxId: params.trxId } : {})
    };
    setSearchParams(preparedData as any);
    await setFieldValue('page', page);
    const data = await ApiService.fetchTransactions<ITransactionResponse>(
      preparedData
    );
    setTransactions(data);
    setLoading(false);
  };

  const {
    handleSubmit,
    setFieldValue,
    setValues,
    values,
    values: { page, limit }
  } = useFormik({
    initialValues: {
      page: 1,
      limit: 20,
      from: '',
      to: '',
      account: '',
      trxId: transactionId || '', // Используем trxId, если передан через URL
      currencies: [] as any
    },
    onSubmit: handlerSubmit
  });

  useEffect(() => {
    handlerSubmit();
    if (!Object.entries(params)?.length) return;
    const preparedParams = {
      ...params,
      ...(params?.trxId ? { trxId: params.trxId } : {}),
      ...(params?.currencies
        ? {
            currencies: Array.isArray(params?.currencies)
              ? params?.currencies?.map((currency: string) => ({
                  value: currency,
                  label: currency
                }))
              : [
                  {
                    value: params?.currencies,
                    label: params?.currencies
                  }
                ]
          }
        : {})
    };
    setValues(preparedParams as any);
  }, [transactionId]); // Добавляем зависимость от transactionId

  return (
    <>
      <Card>
        <Card.Header>
          <TransactionsFilters
            values={values}
            handleSubmit={handleSubmit}
            setFieldValue={setFieldValue}
            loading={loading}
          />
        </Card.Header>
        <TransactionsTable
          transactions={transactions?.data}
          handlePageChange={handlePageChange}
          pagination={{ page, limit, countAll: transactions?.countAll }}
        />
      </Card>
    </>
  );
};

export default Transactions;
