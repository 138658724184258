import React, { useEffect, useRef } from 'react';
import { useFormik } from 'formik';

// Components
import { Form, FormControl, Table } from 'react-bootstrap';
import ActionButton from 'components/common/ActionButton';

// Types
import { ICurrencyPair } from './types';

type PropsTypes = {
  pairs: ICurrencyPair[];
  getCurrencyPairs: () => void;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const PairsTable = (props: PropsTypes) => {
  const { pairs, getCurrencyPairs, setLoading, loading } = props;
  const wrapperRef = useRef(null);

  const handlerSubmit = async () => {
    setLoading(true);
    values[editPairId];
    // Request for update pair
    setFieldValue('editPairId', '');
    getCurrencyPairs(); // After success response
  };

  const handleEdit = (id: string) => {
    setFieldValue('editPairId', id);
  };

  const handleCheck = (pair: ICurrencyPair, value: boolean) => {
    setLoading(true);
    const body = { ...pair, active: value };
    body;
    // Request for update pair
    getCurrencyPairs(); // After success response
  };

  const handleDelete = (id: string) => {
    setLoading(true);
    id;
    // Request for delete pair
    getCurrencyPairs(); // After success response
  };

  const handleClickOutside = (event: any) => {
    if (
      wrapperRef.current &&
      !(wrapperRef?.current as any)?.contains(event.target)
    ) {
      handleSubmit();
    }
  };

  const {
    handleSubmit,
    setValues,
    setFieldValue,
    values,
    values: { editPairId }
  } = useFormik({
    initialValues: {
      editPairId: ''
    } as { [key: string]: ICurrencyPair } & { editPairId: string },
    onSubmit: handlerSubmit
  });

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    if (!pairs) return;
    setValues(
      (prev: { [key: string]: ICurrencyPair } & { editPairId: string }) => ({
        ...prev,
        ...pairs?.reduce(
          (acc: any, { id, ...rest }) => ({ ...acc, [id]: { ...rest } }),
          {}
        )
      })
    );
  }, [pairs]);

  return (
    <Table responsive>
      <thead>
        <tr>
          <th scope="col">Базовая валюта</th>
          <th scope="col">Котируемая валюта</th>
          <th scope="col">Комиссия</th>
          <th className="text-center" scope="col">
            Видимость
          </th>
          <th className="text-end" scope="col">
            Действия
          </th>
        </tr>
      </thead>
      <tbody>
        {pairs?.map((pair, idx) => (
          <tr key={`currency-pair-${idx}`}>
            <td>{pair?.currencyFrom}</td>
            <td>{pair?.currencyTo}</td>
            <td className="w-25">
              {values?.editPairId === pair?.id ? (
                <FormControl
                  ref={wrapperRef}
                  name="percent"
                  type="search"
                  value={values?.[pair?.id]?.percent}
                  onChange={e =>
                    setFieldValue(`${pair?.id}.percent`, e?.target?.value)
                  }
                />
              ) : (
                `${pair?.percent}%`
              )}
            </td>
            <td className="text-center">
              <Form.Check
                type="checkbox"
                id="defaultCheckbox"
                onChange={e => handleCheck(pair, e?.target?.checked)}
                checked={pair?.active}
              />
            </td>
            <td className="text-end">
              <ActionButton
                icon="edit"
                title="Редактировать"
                variant="action"
                onClick={() => handleEdit(pair?.id)}
                className="p-0 me-2 border-0 bg-transparent shadow-none"
                disabled={loading}
              />
              <ActionButton
                icon="trash-alt"
                title="Удалить"
                variant="action"
                onClick={() => handleDelete(pair?.id)}
                className="p-0 me-2 border-0 bg-transparent shadow-none"
                disabled={loading}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default PairsTable;
