import React from 'react';
import NotificationDropdown from 'components/navbar/top/NotificationDropdown';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import { Nav } from 'react-bootstrap';
// import CartNotification from 'components/navbar/top/CartNotification';
// import NineDotMenu from './NineDotMenu';
// import ThemeControlDropdown from './ThemeControlDropdown';
import SupportButton from './SupportButton';
import EmailButton from './EmailButton';

const TopNavRightSideNavItem = () => {
  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      {/* <ThemeControlDropdown /> */}
      {/* <CartNotification /> */}
      <NotificationDropdown />
      {/* <NineDotMenu /> */}
      <SupportButton />
      <EmailButton />
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
