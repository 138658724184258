const currencyPairsData = [
  {
    id: '1',
    currencyFrom: 'BTC',
    currencyTo: 'ETH',
    percent: 0.1,
    active: true
  },
  {
    id: '2',
    currencyFrom: 'ETH',
    currencyTo: 'BTC',
    percent: 0.1,
    active: true
  },
  {
    id: '3',
    currencyFrom: 'ETH',
    currencyTo: 'LTC',
    percent: 0.1,
    active: false
  }
];

export default currencyPairsData;
