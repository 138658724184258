import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';

// Hooks
import useAdvanceTable from 'hooks/useAdvanceTable';

// Providers
import AdvanceTableProvider from 'providers/AdvanceTableProvider';

// Components
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaRegCopy } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SubtleBadge from 'components/common/SubtleBadge';
import { FaMoneyBillTransfer } from 'react-icons/fa6';

// Constants
import { ACCOUNT_STATUSES } from './constants';
import { CURRENCIES } from 'common/constants';

// Types
import { IAccount } from './types';

// Images
import currencies from 'assets/img/icons/currencies';

type AccountsTablePropsTypes = {
  pagination: { page: number; limit: number; countAll?: number };
  accounts?: IAccount[];
  handlePageChange: (page: number) => void;
};

type PreparedAccount = Omit<IAccount, 'kind'> & {
  kind: string;
};

const AccountsTable = (props: AccountsTablePropsTypes) => {
  const { accounts, pagination, handlePageChange } = props;
  const [copiedId, setCopiedId] = useState('');
  const [preparedData, setPreparedData] = useState<PreparedAccount[]>([]);

  const handleCopy = (data: string) => {
    navigator.clipboard.writeText(data);
    setCopiedId(data);
    setTimeout(() => {
      setCopiedId('');
    }, 1500);
  };

  const columns = [
    {
      accessorKey: 'currency',
      header: 'Валюта',
      meta: {
        headerProps: { className: 'text-900 ps-3 py-2' },
        cellProps: { className: 'ps-4 py-2' }
      },
      cell: ({
        row: { original }
      }: {
        row: { original: { currency: any } };
      }) => {
        const { currency } = original;
        return (
          <OverlayTrigger
            overlay={
              <Tooltip style={{ position: 'fixed' }} id="tooltip-top">
                {currency}
              </Tooltip>
            }
          >
            <img
              style={{ height: '30px' }}
              src={(currencies as any)?.[CURRENCIES?.[currency]]}
            ></img>
          </OverlayTrigger>
        );
      }
    },
    {
      accessorKey: 'account',
      header: 'Номер',
      meta: {
        headerProps: { className: 'text-900 ps-4' },
        cellProps: { className: 'ps-4' }
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        const { account } = original;
        return (
          <OverlayTrigger
            overlay={
              <Tooltip
                show={copiedId === account}
                style={{ position: 'fixed' }}
                id="tooltip-top"
              >
                {copiedId === account ? 'Скопировано' : account}
              </Tooltip>
            }
          >
            <div
              role="button"
              onClick={() => handleCopy(account)}
              style={{ maxWidth: '100px' }}
              className="d-flex align-items-center justify-content-between"
            >
              <p className="text-truncate mb-0 user-select-all w-75">
                {account}
              </p>
              <FaRegCopy />
            </div>
          </OverlayTrigger>
        );
      }
    },
    {
      accessorKey: 'name',
      header: 'ФИО',
      meta: {
        headerProps: { className: 'ps-4 text-900' },
        cellProps: { className: 'ps-4' }
      }
    },
    {
      accessorKey: 'sum',
      header: 'Баланс',
      meta: {
        headerProps: { className: 'text-900 ps-4' },
        cellProps: { className: 'ps-4' }
      }
    },
    {
      accessorKey: 'sum_is_reserved',
      header: 'Резерв',
      meta: {
        headerProps: { className: 'text-900 ps-4' },
        cellProps: { className: 'ps-4' }
      }
    },
    {
      accessorKey: 'sum_is_blocked',
      header: 'Заблокировано',
      meta: {
        headerProps: { className: 'text-900 ps-3' },
        cellProps: { className: 'ps-3' }
      }
    },
    {
      accessorKey: '',
      header: 'Общий баланс',
      meta: {
        headerProps: { className: 'text-900 ps-3' },
        cellProps: { className: 'ps-3' }
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        const { sum_is_blocked, sum_is_reserved, sum } = original;
        return <strong>{+sum + +sum_is_blocked + +sum_is_reserved}</strong>;
      }
    },
    {
      accessorKey: 'kind',
      header: 'Статус',
      meta: {
        headerProps: { className: 'text-900 ps-4' },
        cellProps: { className: 'ps-4' }
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        const { kind } = original;
        return (
          <SubtleBadge
            pill
            bg={classNames({
              success: kind === 'active',
              primary: kind === 'pending',
              warning: kind === 'suspended',
              secondary: kind === 'inactive'
            })}
            className="d-flex flex-center"
          >
            <p className="mb-0 h6">{ACCOUNT_STATUSES?.[kind]}</p>
            <FontAwesomeIcon
              icon={
                classNames({
                  check: kind === 'active',
                  redo: kind === 'pending',
                  stream: kind === 'suspended',
                  ban: kind === 'inactive'
                }) as any
              }
              transform="shrink-2"
              className="ms-1"
            />
          </SubtleBadge>
        );
      }
    },
    {
      id: 'transactions',
      meta: {
        headerProps: { className: 'text-900 ps-3' },
        cellProps: { className: 'ps-3' }
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        const { account } = original;
        return (
          <OverlayTrigger
            overlay={
              <Tooltip style={{ position: 'fixed' }} id="tooltip-top">
                Транзакции
              </Tooltip>
            }
          >
            <a href={`/transactions?account=${account}`}>
              <FaMoneyBillTransfer style={{ width: '30px', height: '30px' }} />
            </a>
          </OverlayTrigger>
        );
      }
    },
    {
      accessorKey: 'start_date',
      header: 'Дата создания',
      meta: {
        headerProps: { className: 'text-900 ps-4' },
        cellProps: { className: 'ps-4' }
      }
    },
    {
      accessorKey: 'update_date',
      header: 'Дата обновления',
      meta: {
        headerProps: { className: 'text-900 ps-3' },
        cellProps: { className: 'ps-3' }
      }
    }
  ];

  useEffect(() => {
    if (!accounts?.length) return;
    const result = accounts?.map((dataItem: IAccount) => ({
      ...dataItem,
      start_date: dayjs(dataItem?.start_date)?.format('DD.MM.YYYY hh:mm:ss'),
      update_date: dayjs(dataItem?.update_date)?.format('DD.MM.YYYY hh:mm:ss')
    }));
    setPreparedData(result);
  }, [accounts]);

  const table = useAdvanceTable({
    data: preparedData,
    columns,
    sortable: true,
    pagination: true,
    perPage: pagination?.limit,
    page: pagination?.page,
    rowCount: pagination?.countAll,
    setPage: (page: number) => handlePageChange(++page),
    previousPage: (page: number) => handlePageChange(++page),
    nextPage: (page: number) => handlePageChange(++page + 1)
  } as any);

  return (
    <div className="w-100">
      <AdvanceTableProvider {...table}>
        <Card.Body className="p-0">
          <AdvanceTable
            headerClassName="bg-200 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs-10 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTablePagination />
        </Card.Footer>
      </AdvanceTableProvider>
    </div>
  );
};

export default AccountsTable;
