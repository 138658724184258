import React from 'react';
import PageHeader from 'components/common/PageHeader';
import { Card } from 'react-bootstrap';

const Subscan = () => {
  return (
    <>
      <Card>
        <Card.Body>
          <iframe
            width="100%"
            height="800"
            src="https://explorer.taler.tirol"
          ></iframe>
        </Card.Body>
      </Card>
    </>
  );
};

export default Subscan;
